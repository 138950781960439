import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClpEnvironment } from '@client-portal/environment';
import { AuditLogEntry } from '@client-portal/models/audit-log/audit-log.interface';
import { Observable } from 'rxjs';
import { AuditLogHttpPaths } from './audit-log-http.paths';

@Injectable({
  providedIn: 'root',
})
export class AuditLogHttpService {
  private _http = inject(HttpClient);
  private _env: ClpEnvironment = inject(ClpEnvironment);

  public auditLog$(shiftId: number): Observable<Array<AuditLogEntry>> {
    return this._http.get<Array<AuditLogEntry>>(
      `${this._env.apiEndpoint}${AuditLogHttpPaths.auditLog(shiftId)}`,
    );
  }
}
